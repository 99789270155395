const user_scopes = {
    descriptions: {
        custom_data: '你的自定义数据',
        email: '你的电子邮箱地址',
        phone: '你的电话号码',
        profile: '你的名称，用户名，头像和其他个人资料信息',
        roles: '你的角色',
        identities: '你已连接的第三方账号',
        'urn:logto:scope:organizations': '你的组织信息',
        'urn:logto:scope:organization_roles': '你的组织角色',
        address: '你的地址',
    },
};
export default Object.freeze(user_scopes);
